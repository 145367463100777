import { styled } from "styled-components";

export const HeaderStyled = styled.div`
    z-index: 100;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;

    #nav {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
    }

    .logoHeader,
    .header_button {
        flex-shrink: 1;
    }
    
    li{
        display: inline-block;
    }

    #nav li a {
        display: inline-block;
        padding: 1.5rem;
        transition: .5s;
    }

    #nav li a:hover{
        background: var(--dark);
        transition: .5s;
    }

    .logoHeader {
        width: 9rem;
        margin-left: 10rem;
    }

    .header_button {
        margin-right: 10rem;
        color: white;
    }


    a {
        text-decoration: none;
        color: white;
        font-size: 1.1rem;
        cursor: pointer;
        position: relative;
        text-decoration: none;
        color: white;
    }

    a:focus {
        text-decoration: none;
    }

    @media only screen and (max-width: 1300px) {
        .logoHeader {
            margin-left: 5rem;
            width: 8rem;
        }
        .header_button{
            margin-right: 5rem;
        }
    }

    @media only screen and (max-width: 1046px) {
        .logoHeader {
            margin-left: 3rem;
        }
        .header_button{
            margin-right: 3rem;
        }
    }

    @media only screen and (max-width: 990px) {
        padding: .5rem;

        #nav{
            display: none;
        }
       
        .header_button {
            display: none;
        }

        .burger-icon {
            margin-right: 2rem;
            font-size: 2rem;
            color: var(--white);
        }

        .logoHeader {
            width: 7rem;
            margin-left: 0rem;
        }
    }
`

export const OptionsStyled = styled.div`
    
    display:flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    width: 100%;
    right: 0;

    div{
        background-color: var(--dark);
        display:flex;
        flex-direction: column;
    }
    
    a {
        text-decoration: none;
        color: var(--white);
        padding: 1rem;
        margin-left:1rem;
    }

    .burger_header_button {
        width: 25%;
        position: relative;
        cursor: pointer;
        padding: .8rem;
        margin-left: 1rem;
    }

    @media only screen and (max-width: 768px){
        a {
            font-size: 1rem;
        }
    }

    @media only screen and (max-width: 320px){
        a {
            font-size: .9rem;
        }
    }

`

export const NameStyled = styled.h2`
    padding: 0;
    margin: 0;

`
