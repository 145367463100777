import styled from "styled-components";

export const Div = styled.div`
  margin-top: 0.5rem;
  width: 48%;
  label{
    display: block;
    margin-bottom: 0.2rem;
  }
    
  input{
    width: 100%;
    height: 1.5rem;
    padding: 0.5rem 0.1rem;
    border-radius: 0.2rem;
    border: none;
      &:focus {
        border: none;
        outline: none;
      }
    }

    p {
      color: var(--light-main-red);
      position: absolute;
      margin: 0;
    }
    
    @media only screen and (max-width: 560px) {
      width: 100%;
      label{
        margin-top: .1rem;
      }
      
      p {
        position: relative;
      }
    }

    @media only screen and (max-width: 320px) {
      label{
        font-size: .9rem;
      }
      
      p {
        font-size: 1rem;
      }
    }
`


