import styled from "styled-components";

export const AboutUsStyled = styled.div`
    
    width: 100%;
    height: 100vh;
    display: flex;
    color: var(--white);
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    overflow: hidden;
    object-fit: scale-down;
    position: relative;

    .aboutUs_ImageBackground{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .aboutUsImageMobile{
        display:none;
    }

    #aboutUs-container{
        height: 100%;
        width: 85%;
        gap: 6rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: var(--dark);
        justify-content: space-between;
    }

    #aboutUs{
        width: 50%;
        gap: 4rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .aboutUsPoint span {
        display: inline-block;
        width: 10px; 
        height: 10px; 
        border-radius: 50%;
        background-color: black; 
        margin-right: 5px; 
    }

    #aboutUs h1 {
        margin: 0;
        text-align: start;
        font-size: 1.5rem;
    }

    #aboutUs p {
        font-size: 1.1rem;
        font-weight: lighter;
        color: var(--dark-gray);
        display: contents;
        line-height: 1.2;
    }

    .aboutUsImageContainer{
        width: 100vh;
        height: 100vh;
        // overflow: hidden;
        // display: flex;
    }

    .aboutUsImage {
        // width: 1500px;
        height: 100vh;
        object-fit: cover;
        top: 0;
        right: 0;
        position: absolute;
        
    }
    
    @media only screen and (max-width: 1200px) {
        #aboutUs p {
            line-height: 1.3;
        }
        
        #aboutUs h1 {
            line-height: 1.3;
        }
    }

    @media only screen and (max-width: 990px){
        display: block;
        &{
            height: auto;
        }

        .aboutUsPoint span {
            width: 6px;
            height: 6px;
        }
        
        #aboutUs-container{
            gap: 3rem;
            flex-direction: column;
            width: 100%;
        }

        .aboutUsImageContainer{
            display:none;
        }

        .aboutUsImageMobile{
            display:block;
        }

        .aboutUsImageMobile img{
            width:100%;
        }

        #aboutUs {
            gap: 2rem;
            width: 85%;
            padding: 3rem;
            margin-bottom: 4rem;
        }

        #aboutUs h1 {
            font-size: 1.4rem;
        }

        #aboutUs p {
            font-size: 1rem;
        }

    @media only screen and (max-width: 320px){
        #aboutUs h1 {
            font-size: 1.2rem;
        }

        #aboutUs p {
            font-size: .9rem;
        }
    }
`