import styled from "styled-components";

export const ClientsStyled = styled.div`   
    width: 100%;
    padding: 3rem 0 6rem 0;
    display: flex;
    color: var(--dark);
    height: max-content;
    align-items: center;
    font-weight: lighter;
    flex-direction: column;
    justify-content: center;
    background-color: var(--white);    
    flex: 1;

    #allHabs {
        width: 65%;
        display: flex;
        flex-wrap: wrap;
    }

    .title{
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .title h1{
        margin-top:0;
        text-align: start;
        color: var(--dark);
        font-size: 1.5rem;
        margin-bottom: 3rem;
    }

    #habilities {
        display: flex;
        flex-direction: column; 
        gap: 3rem;
        font-weight: lighter;
        justify-content: center;
    }

    .cardHab {
        gap: 2rem;
        display: flex;
        padding: 3rem;
        justify-content: space-around;
        align-items: center;
        height: 15rem;
        border-radius: 0.3rem;
        color: var(--dark-gray);
        box-shadow: 0 0 2px var(--gray);
    }

    .cardHab:nth-child(even) {
        display: flex;
        flex-direction: row-reverse;
        text-align: start;

    }
    .cardHab:nth-child(odd) {
        text-align: start;
    }

    .cardHab div {
        width: 260px;
    }
      
    .cardHab img {
        animation: slowed 1s;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }
    
    .cardHab p {
        line-height: 1.2;
        width:40%;
        font-size: 1.1rem;
    }

    @media only screen and (max-width: 1200px){
        #allHabs {
            width: 80%;
        }
    }

    @media only screen and (max-width: 990px){

        #allHabs {
            width: 85%;
        }

        .title h1{
            line-height: 1.3;
        }

        #habilities {
            flex-direction: column;
        }

        .cardHab div {
            width: 220px;
        }
    }

    @media only screen and (max-width: 768px){
        .title h1 {
            font-size: 1.4rem;
        }
        
        .cardHab {
            height: auto;
            padding: 2rem 2rem 4rem 2rem;
            flex-direction: column; 
        }
        
        .cardHab p {
            font-size: 1rem;
            width: 100%;
            margin: 0;
            height: inherit;
        }

        .cardHab div {
            width: 200px;
            margin-top: 1rem;
        }

        .cardHab:nth-child(even) {
            display: flex;
            flex-direction: column;
            text-align: justify;
        }
        
        .cardHab:nth-child(odd) {
            text-align: justify;
        }
    }

    @media only screen and (max-width: 425px){
        padding: 3rem 0;

        .cardHab div {
            width: 150px;
        }

        #allHabs {
            width: 85%;
        }

        .cardHab{
            padding: 2rem 2rem 3rem 2rem;
        }
    }

    @media only screen and (max-width: 320px) {
        .title h1 {
            font-size: 1.2rem;
        }
        .cardHab p {
            font-size: .9rem;
        }
    }
`