import React, { useState, useEffect } from 'react';
import { Div } from './style';

interface DropdownListProps {
  label: string;
  options: string[];
  onChange?: (value: string | undefined) => void;
  reset?: boolean;
}

export default function DropdownList({ label, options, onChange, reset }: DropdownListProps) {
  const [selectedOption, setSelectedOption] = useState<string | undefined>(undefined);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newSelectedOption = event.target.value;
    setSelectedOption(newSelectedOption);
    if (onChange) {
      onChange(newSelectedOption === '' ? undefined : newSelectedOption);
    }
  };

  useEffect(() => {
    if (reset) {
      setSelectedOption(undefined);
    }
  }, [reset]);

  return (
    <Div>
      <label>{label}</label>
      <select required onChange={handleSelectChange} value={selectedOption || ''}>
        <option value="" disabled>Selecione uma opção</option>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </Div>
  );
}
