import { ClientsStyled } from "./style";

export default function Clients() {
  return (
    <ClientsStyled>
      <div id="allHabs" style={{ zIndex: "1", fontWeight: "700" }}>
      <div className="title">
        <h1>Elevando as empresas do Brasil para novos patamares de sucesso</h1>
      </div>
        <div id="habilities">
          <div className="cardHab" data-aos="fade-up">
            <div>
              <img src="/images/suhaiLogo.png" alt="Logo Suhai"></img>

            </div>
            <p>
              Revolucionamos o gerenciamento de sinistros e emissão de notas
              fiscais com nossa avançada solução de RPA, automatizando todo o
              processo de forma inteligente e eficiente.
            </p>
          </div>
          <div className="cardHab" data-aos="fade-up">
            <div>
              <img src="/images/autovistLogo.png" alt="Logo Autovist"></img>

            </div>
            <p>
              Criamos o sistema de autovistoria mais abrangente e sofisticado
              disponível no mercado atual.
            </p>
          </div>
          <div className="cardHab" data-aos="fade-up">
            <div>
              <img src="/images/safysignLogo.png" alt="Logo SafySign"></img>
            </div>
            <p>
              Desenvolvemos uma solução de assinatura digital que não apenas
              oferece segurança de ponta, mas também possui validade jurídica
              incontestável
            </p>
          </div>
          <div className="cardHab" data-aos="fade-up">
            <div>
              <img src="/images/checkviewLogo.png" alt="Logo Checkview"></img>

            </div>
            <p>
              Desenvolvemos uma plataforma que integra dados de pessoas físicas, jurídicas e informações veiculares, para tomadas de decisão assertivas, sempre em conformidade com a LGPD. Além disso, a plataforma conta com Onboarding digital inteligente e diversas outras funcionalidades.
            </p>
          </div>
          <div className="cardHab" data-aos="fade-up">
            <div>
              <img src="/images/tabelaSomosLogo.png" alt="Logo Tabela Somos"></img>
            </div>
            <p>
              Criamos um sistema inovador que disponibiliza preços médios de veículos pesados, abrangendo uma ampla gama de itens como tratores, helicópteros, carretas e muito mais. Além da plataforma, desenvolvemos os robôs inteligentes de captação de preços nos diversos portais, tornando a precificação da Tabela Somos escalável e eficiente.
            </p>
          </div>
        </div>
      </div>
    </ClientsStyled>
  );
}
