import { AboutUsStyled } from "./style";

export default function AboutUs() {
  return (
    <AboutUsStyled className="aboutUsDiv">
      <div className="aboutUs_ImageBackground">
        <img
          src="/images/aboutusbackground.jpg"
          alt="about us background"
          style={{
            opacity: "0.1",
            zIndex: "1",
          }}
        />

      </div>
        <div className="aboutUsImageMobile">
          <img src="/images/aboutUs4.png" alt="Um conjunto de 3 fotos. Uma com uma mão mechendo no notbook, uma com um aperto de mão e a ultima com 2 pessoas olhando para o notbook." />
        </div>
      <div id="aboutUs-container">
        <div id="aboutUs" style={{ zIndex: "10" }} data-aos="fade-right">
          <div className="aboutUsPoint">
            <span></span>
            <span></span>
            <span></span>
            <h1>Estamos aqui para descomplicar a jornada das empresas</h1>
          </div>
          <p>
            Como especialistas em desenvolvimento de software, abordamos uma
            ampla gama de áreas para atender às suas necessidades. Seu desafio é
            o nosso desafio, e estamos comprometidos em encontrar a solução
            ideal. Na essência, somos uma empresa dedicada a simplificar
            processos e impulsionar o sucesso dos nossos clientes.
          </p>
        </div>
        <div className="aboutUsImageContainer">
          <img
            className="aboutUsImage"
            src="/images/aboutUs3.png"
            alt="Um conjunto de 3 fotos. Uma com uma mão mechendo no notbook, uma com um aperto de mão e a ultima com 2 pessoas olhando para o notbook."
            style={{ zIndex: "10" }}
          />
        </div>
      </div>
    </AboutUsStyled>
  );
}