import React, { createContext, useContext, useState, ReactNode } from 'react';

interface MyContextType {
  isOpen: boolean;
  updateisOpen: (newData: boolean) => void;
}

const MyContext = createContext<MyContextType | undefined>(undefined);

export const useMyContext = () => {
  const context = useContext(MyContext);
  if (!context) {
    throw new Error('useMyContext must be used within a MyContextProvider');
  }
  return context;
};

interface MyContextProviderProps {
  children: ReactNode; // Definindo children como um tipo ReactNode
}

export const MyContextProvider: React.FC<MyContextProviderProps> = ({ children }) => {
  const [isOpen, setisOpen] = useState<boolean>(false);

  const updateisOpen = (newisOpen: boolean) => {
    setisOpen(newisOpen);
  };

  return (
    <MyContext.Provider value={{ isOpen, updateisOpen }}>
      {children}
    </MyContext.Provider>
  );
};
