import { styled } from "styled-components";

export const FooterStyled = styled.div `

    height: 15vh;
    display: flex;
    align-items: flex-end;
    padding: 1.5rem 8rem;
    background-color: var(--not-white);
    justify-content: center;
    color: var(--dark);
    padding: 2rem;

    p {
        font-size: 1.1rem;
        text-align: center;
    }

    @media only screen and (max-width: 768px) {
        p {
            font-size: 1rem;
        }
    }
    
    @media only screen and (max-width: 320px) {
        p {
            font-size: .9rem;
        }
    }

`