import { styled } from "styled-components";

export const ExpirenceStyled = styled.div`

    z-index: 1;
    width: 100% ;
    height: max-content;
    display: flex;
    color: var(--white);
    align-items: center;
    background-size: cover;
    flex-direction: column;
    max-height: max-content;
    justify-content: center;
    image-orientation: 90deg;
    background-color: var(--black);
    
    .all {
        text-align: justify;
        width: 85%;
        padding: 4rem 0rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .full_width_slide {
        width: 100%;
    }

    #titleServices {
        display: flex;
        margin-bottom: 1rem;
        flex-direction: column;
        align-items: center;
        font-size: 1rem;
    }
    
    #titleServices h2 {
        text-align: start;
        font-size: 1.5rem;
        margin: 0;
    }

    .work_text h1,
    #titleServices h3{
        font-size: 1.2rem;
    }

    #titleServices h3{
        font-weight: 500;
    }
    
    #expiriences{
        gap: 1rem;
        z-index: 1;
        // width: 90%;
        display: flex;
        font-size: 1rem;
        // align-items: center;
        justify-content: space-between;
        // justify-content: center;
    }

    .services {
        flex-wrap:wrap;
        gap:2rem;
        padding:2rem 0;
        display: flex;
        flex-direction: row;
    }

    .work_expirience {
        align-items:flex-end;
        justify-content:center;
        position:relative;
        max-width: 150px;
        min-width: 50px;
        min-height: 400px;
        max-height: 400px;
        text-align:center;
        cursor: pointer;
        background-position: center;
        background-repeat:no-repeat;
        background-size:cover;
        border-radius: 10px;
        transition: max-width 1.3s ease-in-out, min-width 1.3s ease;
        animation: shrinkCard 1.3s forwards;
        padding: 1rem;
        overflow: hidden; 
        position: relative; 
    }

    .active_initial {
        animation: expandCard 1.3s forwards;
        max-width:400px;
        min-width: 150px;
        width:400px;
        border-radius: 25px;
        transition: max-width 1.3s ease-in-out;
        justify-content: center;
        display: flex;
        align-items: center;
    }

    .work_text {
        padding: 2rem;
        transition: opacity 4s ease, transform 4s ease;
    }
    
    @keyframes fadeInText {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 0;
        }
        90% {
            opacity: 1;
        }
    }
    
    .active_text {
        animation: fadeInText 2s ease forwards; 
        padding: 2rem;
        position: relative;
        text-align: justify;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .work_text_div{
        position:absolute;
        inset:0;
        transition-delay: 1s;
    }

    .active_text_div{
        all:unset;
    }

    .work_text_h{
        text-transform: uppercase;
        white-space: nowrap;
        margin: 0;
        transform: rotate(-90deg) translatex(-350px);
        color: black;
    }

    @keyframes transformAnimation {
        0% {
            opacity:0;
        }
        50% {
            opacity:0;
        }
        80% {
            transform: unset;
        }
    }

    .active_text_h{
        animation: transformAnimation 1s ease forwards; 
        transform: unset;
        padding-bottom: .2rem;
        white-space: normal;
        text-align: justify;
    }

    .work_text_p{
        position:absolute;
        opacity:0;
        color: black;
    }

    .active_paragraph {
        position:relative;
        opacity: 1;
        max-height:100%;
        color: black;
    }

    .work_text_p {
        line-height: 1.2;
        font-size: 1.1rem;
        margin: 0;
    }

    .paragraph {
        color: var(--white);
        display: flex;
        justify-content: center;
        text-align: center;
        margin-top: 1rem;
        letter-spacing: 0.1rem;
        font-size: 1.1rem;
    }

    @keyframes expandCard {
        0% {
            max-width: 150px;
            min-width: 50px;
        }
        100% {
            max-width: 400px;
            min-width: 150px;
        }
    }

    @keyframes shrinkCard {
        from {
            max-width: 400px;
            min-width: 300px;
        }
        to {
            max-width: 400px;
            min-width: 50px;
        }
    }

    @media only screen and (max-width: 1318px){
        .work_expirience{
            padding: .5rem;
        }

        .services{
            gap: 1rem;
        }

    }

    @media only screen and (max-width: 1150px){
        .services{
            gap: .5rem;
        }
    }

    @media only screen and (max-width: 990px) {

        .services{
            display: flex;
            flex-direction: column;
            align-items: start;
        }

        #titleServices {
            align-items: flex-start;
        }

        #titleServices h2 {
            font-size: 1.2rem;
            color: var(--lilac);
        }

        #titleServices h3{
            font-size: 1.3rem;
        }

        .full_width_slide,
        .container_work_expirience{
            width: 100%;
        }

        .work_expirience {
            min-height: 400px;
            padding: 0;
            transition: none;
            height: 100%;
            animation: none;
            min-width: 100%;
            border-radius: 10px;
            position: relative; 
        }

        .active_initial {
            min-width: 100%;
            width: 100%;
            height: 400px;
            border-radius: 10px;
            justify-content: center;
            display: flex;
            align-items: center;
        }

        .work_text_h {
           transform: none;
           margin-top: 1.5rem;
           text-align: start;
           padding-left: 1rem;
        }

        .active_text_h{
            text-align: start;
            padding-left: 0;
        }

        .active_text{
            text-align: justify;
        }

        .work_expirience:hover .work_text_h{
            padding-left: 0;
        }

        .work_text h1 {
            font-size: 1.1rem;
        }

        .work_text_p{
            font-size: 1rem;
            opacity: 1;
            position: relative;
        }

        .paragraph{
            font-size: .9rem;
            font-weight: 700;
        }

        .work_text {
            padding: 1rem;
            gap: 1rem;
            text-align: justify;
        }
    }

    @media only screen and (max-width: 320px) {
        #titleServices h2 {
            font-size: 1.2rem;
        }
        
        #titleServices h3,
        .work_text h1 {
            font-size: 1rem;
        }

        .work_text_p {
            font-size: .9rem;
        }

        .paragraph{
            font-size: .8rem;
        }
    }

`