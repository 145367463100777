import React, { useRef, useState } from "react";
import Slider, { Settings } from "react-slick";
import { SlickDots } from "./style";

interface SlideProps {
  children: JSX.Element[];
  className?: string;
}

export function Slide({ children, className }: SlideProps) {
  const sliderRef = useRef<Slider>(null);
  const [activeDotIndex, setActiveDotIndex] = useState(0);
  const [isTouching, setIsTouching] = useState(false);
  const [isAutoplayPaused, setIsAutoplayPaused] = useState(false);

  const settings: Settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 10000,
    autoplay: !isTouching && !isAutoplayPaused,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: true,
    swipeToSlide: true,
    arrows: false,
    beforeChange: (oldIndex, newIndex) => {
      setActiveDotIndex(newIndex);
    },
    appendDots: (dots) => (
      <SlickDots>
        {Array.isArray(dots) &&
          dots.map((dot, index) => (
            <li
              key={index}
              className={index === activeDotIndex ? "active" : ""}
              onClick={() => {
                if (sliderRef.current) {
                  sliderRef.current.slickGoTo(index);
                }
              }}
            >
              <span className={`dot-span ${isAutoplayPaused ? "paused" : ""}`}></span>
            </li>
          ))}
      </SlickDots>
    ),
  };

  const handleSlideClick = (e: React.MouseEvent) => {
    const { left, width } = e.currentTarget.getBoundingClientRect();
    const clickX = e.clientX - left;

    if (clickX > width / 2) {
      if (sliderRef.current) {
        sliderRef.current.slickNext();
      }
    } else {
      if (sliderRef.current) {
        sliderRef.current.slickPrev();
      }
    }
    setIsTouching(true);
    setTimeout(() => {
      setIsTouching(false);
      if (!isAutoplayPaused && sliderRef.current) {
        sliderRef.current.slickPlay();
      }
    }, 1000);
  };

  const handleTouchStart = () => {
    setIsTouching(true);
    if (sliderRef.current) {
      sliderRef.current.slickPause();
      setIsAutoplayPaused(true);
    }
  };

  const handleTouchEnd = () => {
    setIsTouching(false);
    if (sliderRef.current) {
      sliderRef.current.slickPlay();
      setIsAutoplayPaused(false);
    }
  };

  return (
    <div
      onClick={handleSlideClick}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <Slider className={className} ref={sliderRef} {...settings}>
        {children.map((child, index) => (
          <div key={index}>{child}</div>
        ))}
      </Slider>
    </div>
  );
}
