import React, { useState } from 'react';
import { HiArrowRight } from 'react-icons/hi';
import { StyledLink } from './style';
import { useMyContext } from '../../Sidebar/MyContextProvider';

interface ButtonProps {
  children: string;
  onClick?: () => void;
}

const Button: React.FC<ButtonProps> = ({ children, onClick }) => {
  const { isOpen, updateisOpen } = useMyContext();
  const [isProcessingClick, setIsProcessingClick] = useState(false);

  const toggleSidebar = () => {
    if (!isProcessingClick) {
      setIsProcessingClick(true);

      setTimeout(() => {
        updateisOpen(!isOpen);
        setIsProcessingClick(false);
      }, 100);
    }
  };

  return (
    <StyledLink onClick={toggleSidebar}>
      <span>{children}</span>
      <HiArrowRight className='icon' />
    </StyledLink>
  );
}
export default Button;