import styled from "styled-components";

export const Div = styled.div`
  margin-top: 0.5rem;
  width: 48%;

  label {
    display: block;
    margin-bottom: 0.2rem;
  }

  select {
    color: var(--dark-gray);
    font-size: .9rem;
    padding: 0.4rem;
    width: 100%;
    height: 2.4rem;
    border-radius: 0.2rem;
    border: none;
    box-shadow: 0 0 2px var(--gray);

    &:valid,
    &:focus {
      border: none;
      outline: none;
      color: var(--dark);
    }

    option {
      background-color: var(--dark-gray);
      color: var(--white);
      height: 2.4rem;
      padding: 1rem;
      font-size: .9rem;
      box-shadow: 0 0 2px var(--gray);
    }

    option:disabled {
      color: var(--gray);
    }
  }

  @media only screen and (max-width: 1310px) {
    option{
        font-size: .9rem;
    }

  }
  @media only screen and (max-width: 560px) {
    width: 100%;
    
    option{
        font-size: 0.5rem;
        padding: 0.5rem;
    }
  }

  @media only screen and (max-width: 375px) {
    width: 100%;
    
    option{
        font-size: 0.5rem;
        padding: 0.5rem;
    }
  }
`;
