import InputMask from 'react-input-mask';
import { Div } from "./style";

interface FieldProps {
  type?: string;
  placeholder?: string;
  label?: string;
  required?: boolean;
  value?: string;
  onChange?: (value: string) => void;
  errorMessage?: string;
  onBlur?: () => void;
}

export default function Field({
  label,
  type = "text",
  required,
  placeholder,
  value,
  onChange,
  errorMessage,
  onBlur,
}: FieldProps) {
  const isTextType = type === 'text';

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = isTextType ? e.target.value.replace(/[^a-zA-Z\s]/g, '') : e.target.value;
    onChange && onChange(inputValue);
  };

  return (
    <Div className="container_size">
      <label>{label}</label>
      {type === 'tel' ? (
        <InputMask
          required
          mask="(99) 9 9999-9999"
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange && onChange(e.target.value)}
          onBlur={onBlur}
        />
      ) : (
        <input
          type={isTextType ? 'text' : type}
          required={required}
          placeholder={placeholder}
          value={value}
          onChange={handleInputChange}
          onBlur={onBlur}
          pattern={isTextType ? '[A-Za-z\\s]+' : undefined}
        />
      )}
      {required && value && errorMessage && (
        <p>{errorMessage}</p>
      )}
    </Div>
  );
}
