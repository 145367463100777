import { MainStyled } from "./style";
import Button from "./../components/Button";

export default function Main() {
  return (
    <MainStyled>
      <div id="video" className="background_video_container">
        <img className="background_video" src="/images/HomeBackground.jpg" alt="" />
      </div>
      <div className="main_container">
        <div className="quote">
          <h1>
            OTIMIZE O DESEMPENHO DE SUA EMPRESA COM SOLUÇÕES INTELIGENTES DA SQAD!
          </h1>
          <h2>
            Que tal revolucionar sua operação, substituindo{" "}
            <span className="highlight_negative">tarefas manuais</span> e{" "}
            <span className="highlight_negative">repetitivas</span> por{" "}
            <span className="highlight_positive">automações inteligentes e personalizadas?</span>
          </h2>
          <p className="text">
            Desperte uma nova era de eficiência, produtividade e crescimento com
            nossa expertise em transformação digital.
            Juntos, vamos impulsionar sua empresa rumo ao sucesso com uma abordagem ágil e inovadora.
          </p>
          <div className="container_button">
            <Button >FALE COM UM ESPECIALISTA</Button>
          </div>
        </div>
        <div className="quote_container">
          <img className="quote_img" src="/images/HomeImage2.png" alt="Imagem de um grupo de pessoas trabalhando juntos" />
        </div>
      </div>
    </MainStyled>
  );
}
