import { styled } from "styled-components";

export const MainStyled = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  color: var(--dark);
  overflow: hidden;
  align-items: center;
  object-fit: scale-down;
  justify-content: center;
  scroll-snap-type: y mandatory;
  position: relative;
  // padding: 4rem 0;
  gap: 2rem;
  
  .main_container{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 85%;
    justify-content: space-between;
    gap: 2rem;
  }

  .background_video_container {
    filter: grayscale(1);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .background_video_container video::-webkit-media-controls-start-playback-button {
    display: none !important;
  }

  .background_video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .quote {
    gap: 4rem;
    position: relative;
    z-index: 1;
    justify-content:center;
    display:flex;
    flex-direction:column;
    text-align: center;
  } 
  
  .quote h1 {
    margin:0;
    text-align: start;
    font-size: 1.2rem;
    font-weight: 400;
    color: var(--white);
  }

  .quote h2 {
    margin: 0;
    color: var(--white);
    font-size: 1.2rem;
    line-height: 1.2rem;
    text-align: start;
  }

  .highlight_negative {
    color: var(--lilac);
  }
  
  .highlight_positive {
    color: var(--lilac);
  }

  .text {
    margin:0;
    color: var(--white);
    font-size: 1.1rem;
    font-weight: 300;
    line-height: 1.2rem;
    text-align: start;
  }

  .container_button {
    justify-content: start;
    display:flex;
    align-items: center;
  }

  .quote_container{
    object-fit: cover;
    // padding: 0 5rem 0 0 ;
    position:relative;
    top: -4rem;
  }

  .quote_img{
    position: sticky;
    z-index: 1;
    width: 20rem;
    object-fit: cover;
  }

  @media only screen and (max-width: 1300px) {
    .quote{
      transform: translatey(20px);
      gap: 2rem;
    }

    .quote_container{
      top: -2rem;
    }
  }
  

  @media only screen and (max-width: 1200px) {
    .quote_img{
      width: 20rem;
    }
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;

    .quote_img{
      display:none;
    }

    .quote{
      padding: 0;
    }

    .quote h2 {
      margin: 0;
    }

    .quote h1,
    .quote h2,
    .text {
      font-size: 1rem;
    }
  }

  @media only screen and (max-width: 320px) {

    .quote h1,
    .quote h2,
    .text{
      font-size: .9rem;
    }
  }
`;
