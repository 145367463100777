import Main from "../Main";
import Footer from "../Footer";
import Header from "../Header";
import AboutUs from "../AboutUs";
import Clients from "../Clients";
import Expirence from "../Experience";
import { BrowserRouter as Router } from 'react-router-dom';

export default function Home() {

  return (
    <Router>
      <Header />
      <section id="main">
        <Main />
      </section>
      <section id='clients'>
        <Clients />
      </section>
      <section id='aboutus'>
        <AboutUs />
      </section>
      <section id='services'>
        <Expirence />
      </section>
      <section>
        <Footer />
      </section>
    </Router>
  );
}