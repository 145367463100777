import { useState, useEffect } from "react";
import Sidebar from "../Sidebar";
import { HeaderStyled, OptionsStyled } from "./style";
import { IoIosMenu } from "react-icons/io";
import Button from "../components/Button";
import { Link } from "react-router-dom";

export default function Header() {
    const [showBurgerMenu, setShowBurgerMenu] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const [shouldCloseForm, setShouldCloseForm] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setShowBurgerMenu(window.innerWidth <= 990);
        };

        handleResize();

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const toggleOptions = () => {
        setShowOptions(!showOptions);
    }

    const handleOptionClick = () => {
        // Esconde apenas as opções quando uma opção é clicada
        setShowOptions(false);
    };

    useEffect(() => {
        const handleOutsideClick = (e: MouseEvent) => {
            const burger = document.querySelector('.burger-icon');
            if (burger && !burger.contains(e.target as Node) && !shouldCloseForm) {
                setShowOptions(false);
                setShouldCloseForm(true);
            } else {
                setShouldCloseForm(false);
            }
        }
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [shouldCloseForm, setShowOptions]);

    return (
        <HeaderStyled>
            <div className="logo_header">
                <Link to='/#main' onClick={() => window.scrollTo(0, 0)}>
                    <img className="logoHeader" alt="Logo" src="/images/logo-sqad.png" />
                </Link>
            </div>
            <div id="nav" className={showBurgerMenu ? "hide" : ""}>
                <li>
                    <a href="/#clients">CLIENTES</a>
                </li>
                <li>
                    <a href="/#aboutus">QUEM SOMOS</a>
                </li>
                <li>
                    <a href="/#services">SERVIÇOS</a>
                </li>
            </div>
            <div className="header_button">
                <Button>FALE COM UM ESPECIALISTA</Button>
            </div>
            <Sidebar />
            {showBurgerMenu && (
                <>
                    <IoIosMenu className="burger-icon" onClick={toggleOptions} style={{ marginRight: "1rem" }} />
                    {showOptions && (
                        <OptionsStyled >
                            <div data-aos="fade-down"
                                data-aos-easing="linear"
                                data-aos-duration="200"
                            >
                                <a onClick={handleOptionClick} href="/#clients">CLIENTES</a>
                                <a onClick={handleOptionClick} href="/#aboutus">QUEM SOMOS</a>
                                <a onClick={handleOptionClick} href="/#services">SERVIÇOS</a>
                                <div className="burger_header_button">
                                    <Button>FALE COM UM ESPECIALISTA</Button>
                                </div>
                            </div>
                        </OptionsStyled>
                    )}
                </>
            )}
        </HeaderStyled>
    );
}

