import styled, { keyframes } from "styled-components";

const fillAnimation = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
`;

export const SlickDots = styled.ul`
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
  top: 30px;
  // z-index: -1;
  scroll-behavior: smooth;

  li {
    width: 180px;
    height: 3px;
    margin: 0 10px;
    border-radius: 0%;
    background: #6a6a6a; 
    cursor: pointer;
    position: relative;

    &.active .dot-span {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 0; 
      background-color: var(--white); 
      animation: ${fillAnimation} 10s linear forwards; 
    }

    .dot-span {
      display: block;
      height: 100%;
    }

    & .dot-span.paused {
      animation-play-state: paused;
    }
  }
`;
