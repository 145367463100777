import styled from "styled-components";

export const StyledLink = styled.button`
  display: flex;
  justify-content: center;
  color: var(--white);
  border-radius: .3rem;
  border: 1px solid var(--white);
  width: 250px;
  gap: 0.5rem;
  padding: .8rem;
  align-items: center;
  z-index: 1;
  overflow: hidden;
  position: relative;
  
  &:hover {
    background: var(--white);
    border-color: var(--black);
    transition: .5s;
    color: var(--black);
  }

  &::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: var(--white);
    z-index: -1;
    transition: .6s;
  }
  
  &:hover::before {
    width: 100%;
  }

  @media only screen and (max-width: 642px) {
    width: 230px;
    padding: .7rem;
    span{
      font-size: .8rem;
    }
    .icon{
      font-size: .8rem;

    }
  }
  @media only screen and (max-width: 442px) {
    width: 250px;
    padding: .6rem;
    span{
      font-size: .9rem;
    }
    .icon{
      font-size: .7rem;
    }
  }

  @media only screen and (max-width: 375px) {
    width: 190px;
    padding: .5rem;
    span{
      font-size: .6rem;
    }
    .icon{
      font-size: .6rem;
    }
  }

  @media only screen and (max-width: 280px){
    width: 180px;
    padding: .4rem;
    span{
      font-size: .5rem;
    }
    .icon{
      font-size: .5rem;
    }
  }

`;
