import { useEffect, useState } from "react";
import { ExpirenceStyled } from "./style";
import { useInView } from "react-intersection-observer";
import { Slide } from "../components/Slider";

interface Experience {
  title: string;
  description: string;
  image: string;
}

const experiences: Experience[] = [
  {
    title: "Automações Inteligentes",
    description: "Se sua empresa busca aprimorar a eficiência dos seus processos, conte com nossa equipe especializada para desenvolver soluções de automação inteligente.",
    image: "/images/automation.png"
  },
  {
    title: "Aplicações Mobile",
    description: "Criamos aplicativos nativos intuitivos e de alto desempenho para iOS e Android, atendendo às demandas exigentes de diversos públicos.",
    image: "/images/mobile.png"
  },
  {
    title: "Sistemas Web",
    description: "Despertamos suas ideias para a vida real, construindo projetos completos de ponta a ponta, com especialização tanto em back-end quanto em front-end.",
    image: "/images/sistema.png"
  },
  {
    title: "Desenvolvimento Front-end",
    description: "Utilizamos tecnologias de ponta como React, Vue e NextJs para garantir agilidade no desenvolvimento, manutenção e uma experiência excepcional para o usuário final.",
    image: "/images/frontEnd.png"
  },
  {
    title: "DevOps e Cloud",
    description: "Preparamos seu ambiente para disponibilizar serviços em nuvem de forma escalável, permitindo que sua aplicação atenda seu público com máxima eficiência no melhor custo.",
    image: "/images/devOps.png"
  },
  {
    title: "Desenvolvimento de APIs",
    description: "Possuímos a expertise em desenvolver APIs REST de alto desempenho, meticulosamente documentadas e de fácil integração, proporcionando escalabilidade e segurança para sua aplicação.",
    image: "/images/api.png"
  }
];

export default function Expirence() {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [lastHoveredIndex, setLastHoveredIndex] = useState<number>(0);
  const { ref, inView } = useInView();
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    if (inView) {
      setActiveIndex(lastHoveredIndex);
    }
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 990);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [inView, lastHoveredIndex]);

  const handleHover = (index: number) => {
    setLastHoveredIndex(index);
  };

  const handleClick = (index: number) => {
    setActiveIndex(index);
    setLastHoveredIndex(index);
  };

  return (
    <ExpirenceStyled ref={ref}>
      <div className="all" data-aos="zoom-in">
        <div id="titleServices">
          <h2>Elevando sua empresa para novos patamares</h2>
          <h3>
            Na busca pela excelência, nossos serviços oferecem uma ampla gama de
            soluções adaptáveis para cada desafio que sua empresa enfrenta:
          </h3>
        </div>
        {isSmallScreen ? (
          <div className="container_work_expirience">
            <Slide className="full_width_slide">
              {experiences.map((experience, index) => (
                <div key={index}>
                  <div className="work_expirience active_initial" style={{ backgroundImage: `url(${experience.image})` }}>
                    <div className="work_text active_text">
                      <div className="work_text_div active_text_div">
                        <h1 className="work_text_h active_text_h">{experience.title}</h1>
                      </div>
                      <p className="work_text_p active_paragraph">{experience.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Slide>
          </div>
        ) : (
          <div id="expiriences">
            <div className="services">
              {experiences.map((experience: Experience, index: number) => (
                <div
                  key={index}
                  className={`work_expirience ${index === activeIndex ? "active_initial" : ""}`}
                  onMouseOver={() => handleHover(index)}
                  onClick={() => handleClick(index)}
                  style={{ backgroundImage: `url(${experience.image})` }}
                >
                  <div className={`work_text ${index === activeIndex ? "active_text" : ""}`}>
                    <div className={`work_text_div ${index === activeIndex ? "active_text_div" : ""}`}>
                      <h1 className={`work_text_h ${index === activeIndex ? "active_text_h" : ""}`}>
                        {experience.title}
                      </h1>
                    </div>
                    <p className={`work_text_p ${index === activeIndex ? "active_paragraph" : ""}`}>
                      {experience.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        <p className="paragraph">
          Estamos comprometidos em impulsionar o crescimento e o sucesso da sua empresa, oferecendo soluções personalizadas e de alta qualidade em todas as etapas do desenvolvimento de software.
        </p>
      </div>
    </ExpirenceStyled>
  );
}